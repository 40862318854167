.popUp{
  width: 1280px;
  height: 720px;
  cursor: initial;
  max-width: 100%;
}

.alertDialogMain {
  width: 100%;
  height: 100%;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent linear-gradient(90deg, #00000087 0%, #0000006E 40%, #00000000 60%, #00000000 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.higlightBackgroundOverlay{
  border-radius: none;
}

.alertDialogInformation{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 30px;

  @media screen and (min-width: 768px) {
    padding: 60px;
  }
}

.highlightImage {
  position: relative;
  width: 100%;
  height: 100%;
}

.highlightDialogTitle{
  font-size: 42px;
  font-weight: 500;
  font-family: var(--header-font);
  margin-bottom: 10px;
  color: white;
  text-transform: uppercase;
}

.alertSubTitle {
  font-size: 14px;
  font-weight: 500;
  font-family: var(--header-font);
  color: white;
  text-transform: uppercase;
}

.alertDescription{
  width: 399px;
  max-width: 100%;
}

.alertDescription>p{
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5rem;
  color: white;
  padding-top: 0;
  font-family: var(--body-font);
  text-shadow: 0px 3px 6px #000000BA;
}

.alertDescription>p:first-of-type {
  margin-top: 30px;
}

.alertDialogClose{
  position: absolute;
  top: 20px;
  color: white !important;
  right: 30px;
  cursor: pointer;

  @media screen and (min-width: 768px) {
    right: 60px;
    top: 60px;
  }
}

.alertDialogCloseIcon{
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));
}

.alertDialogClose:hover{
  background-color: transparent !important;
}

.alertDialogBtn {
  position: absolute;
  bottom: 50px;
  min-width: 200px;
  color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  right: 60px;
  cursor: pointer;

  @media screen and (max-width: 620px) {
      right: 50%;
      min-width: 80%;
      transform: translateX(50%);
  }
}