.outterContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.title {
  margin-top: calc(-0.2em + 30px);
}

.container {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }

  &:first-child {
    z-index: 2;

    .textContent {
      padding-bottom: 10px;
    }
  }

  .imageContainer {
    @media screen and (min-width: 768px) {
      border-left: 30px solid white;
      border-bottom: 30px solid white;
    }
  }

  &:nth-child(2) {
    .imageContainer {
      @media screen and (min-width: 768px) {
        position: absolute;
        bottom: 0;
        top: initial;
        border-bottom: 0;
        height: 100%;
        right: 0;
        left: initial;
      }
    }

    
    .imageContainer.imageContainerWide {
      @media screen and (min-width: 768px) {
        left: 0;
        right: initial;
      }
    }

    .textContent {
      @media screen and (min-width: 768px) {
        padding-top: 170px;
        padding-left: 0;
        padding-bottom: 60px;
      }
    }
  }
}

.imageContent {
  position: relative;
  flex-grow: 1;
  min-width: calc(55% + 15px);
  transform: translateX(0);
}

.text {
  max-width: 100%;
  width: 450px;
  margin-bottom: 1.5em;
  min-height: 140px;
}

.containerFlipped {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media screen and (min-width: 768px) {
    flex-direction: row-reverse;
  }

  &:first-child {
    z-index: 2;

    .textContent {
      padding-bottom: 10px;
    }

    .imageContainer.imageContainerWide {
      @media screen and (min-width: 768px) {
        right: 0;
        left: initial;
      }
    }
  }


  .imageContent {
    transform: translateX(0);
  }

  &:nth-child(2) {
    .imageContainer {
      position: relative;
      left: 0;
      @media screen and (min-width: 768px) {
        position: absolute;
        bottom: 0;
        top: initial;
        border-bottom: 0;
        height: 100%;
        right: 0;
        left: initial;
        border-left: 0;
      }
    }

    .textContent {
      @media screen and (min-width: 768px) {
        padding-top: 200px;
        padding-left: 0;
        padding-bottom: 60px;
      }
    }
  }
  

  .imageContainer {
    @media screen and (min-width: 768px) {
      border-right: 30px solid white;
      border-bottom: 30px solid white;
    }
  }
}


.imageContainer {
  position: relative;
  width: calc(100% - 30px);
  height: 250px;
  left: 30px;
  @media screen and (min-width: 768px) {
    position: absolute;
    width: 100%;
    height: calc(100% + 200px);
    left: 0;
    top: 0;
  }
}

.imageContainerWide {
  @media screen and (min-width: 768px) {
    width: calc((100vw / 2) + 86px);
  }
}

.textContent {
  flex-grow: 1;
  min-width: calc(33.33% - 30px);
}

.imageDescription {
  position: absolute;
  left: -3px;
  transform: rotate(90deg);
  transform-origin: top left;
  color: #666;
  font-size: 16px;
}

.imageDescriptionFlipped {
  position: absolute;
  right: -3px;
  transform: rotate(90deg);
  transform-origin: bottom right;
  color: #666;
  font-size: 16px;
  @media screen and (min-width: 768px) {
    right: -3px;
    transform: rotate(90deg) translateX(100%);
    transform-origin: bottom right;
  }
}

.container:first-child {
  & > div > figure > figcaption {
    top: 0;
  }
}

.containerFlipped:first-child {
  & > div > figure > figcaption {
    top: -20px;
  }
}

.container:nth-child(2) {
  & > div > figure > figcaption {
    top: 175px;
  }
}

.containerFlipped:nth-child(2) {
  & > div > figure > figcaption {
    top: 112px;
    @media screen and (min-width: 768px) {
      top: 148px;
    }

    /* Fix for quote text not sitting in the correct position on mobile */
    @media screen and (max-width: 767px) {
      transform: translateY(42px) rotate(90deg);
    }
  }
}