.main {
    width: 100%;
    height: fit-content;
    position: relative;
    max-height: 100vh;
}

.overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background: transparent linear-gradient(180deg, #ABABAB 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
    opacity: 1;
    top: 0;
    left: 0;
}

.overlayShort {
    width: 100%;
    height: 100%;
    position: absolute;
    background: transparent linear-gradient(180deg, #5c5b5b 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
    opacity: 1;
    top: 0;
    left: 0;
}


.title {
    color: white;
    font-weight: 400;
    margin-top: 10px;
    font-family: var(--header-font);
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom: 10px;
    text-align: left;
    justify-content: left;
    margin-left: -4px;
}

.statIconStyle{
    filter: invert(1) brightness(100);
}

.stats{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 18px;
    background-color: var(--color-red-10);
    width: fit-content;
    padding: 10px;
}

.label{
    font-size: 14px;
    color: white;
    font-weight: 500;
    font-family: var(--header-font);
    letter-spacing: 0.2em;
    text-transform: uppercase;
    text-align: left;
    justify-content: left;
}

.statItem{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 5px;
}

.statImage{
    position: relative;
    width: 14px;
    height: 14px;
}

.subtitle {
    font-size: 18px;
    color: white;
    font-weight: 500;
    margin-top: 5%;
    font-family: var(--header-font);
    letter-spacing: 0.2em;
    text-transform: uppercase;
    text-align: left;
    justify-content: left;
}

.headerScrollButtonHolder {
    width: 100%;
    height: 80px;
    margin-top: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    animation: bounce 1s infinite;
}

.statsVisible {
    margin-top: 110px;
}

.headerScrollButton {
    margin-top: 12px;
    cursor: pointer;
    transition: 500ms;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.headerScrollButtonTitle {
    font-size: 15px;
    color: white;
    font-weight: 200;
    font-family: var(--header-font);
    letter-spacing: 0.2em;
    text-transform: uppercase;
    text-align: left;
    justify-content: left;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.headerScrollButton:hover {
    transform: translateY(5px);
}

.imageWrapper {
    width: 100%;
    height: 800px;
    @media screen and (max-width: 991px) {
        min-height: 100vh;
        height: auto;
    }
    position: relative;
}

.imageWrapperMedium {
    width: 100%;
    height: 400px;
    @media screen and (max-width: 991px) {
        height: 400px;
    }
    position: relative;
}

.imageWrapperShort {
    width: 100%;
    height: 100px;
    position: relative;
}

.container {
    position: absolute;
    top: 50%;
    margin: auto;
    left: 0;
    right: 0;
}

.searchContainer {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    z-index: 1;

    /* Fix: z-index issue with mobile search not overlapping navigation and other header elements */
    &:has([class*="advancedRegionMobileOpen"]) {
        z-index: 100;
    }
}

@keyframes bounce {
    0%, 100% {
      transform: translateY(-25%);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: translateY(0);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }