.Navigation_wrapper__Iu3Dy {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  height: 72px;
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
}

  @media screen and (max-width: 991px) {.Navigation_wrapper__Iu3Dy {
    /* */
    gap: 15px
}
  }

  @media screen and (min-width: 576px) {.Navigation_wrapper__Iu3Dy {
    margin-left: 0;
    margin-right: 0
}
  }

.Navigation_menuButton__Q_yVt, .Navigation_phoneButton__akoT2 {
  position: relative;
  font-size: 28px;
  line-height: 1em;
  padding: 10px;
  width: 48px;
  height: 48px;
  text-align: center;
  color: white;
}

@media screen and (min-width: 993px) {

.Navigation_menuButton__Q_yVt, .Navigation_phoneButton__akoT2 {
    display: none
}
  }

.Navigation_menuButton__Q_yVt>svg {
    position: absolute;
    top: 12px;
    left: 12px;
    transform-origin: center;
    transition: transform 0.2s;
  }

.Navigation_menuButtonOpen__tBUe8>svg {
    transform: rotate(-90deg);
  }

.Navigation_mobileMenuOpen__70wlh {
  
}

.Navigation_mobileMenuClosed__ftE2M {

}

.Navigation_text__Gf1zS {
  display: none;
}

@media screen and (min-width: 993px) {

.Navigation_text__Gf1zS {
    display: block
}
  }

.Navigation_topBar__wiQEm {
  background-color: var(--color-red-10);
  height: 24px;
  font-size: 14px;
}

@media screen and (max-width: 993px) {

.Navigation_topBar__wiQEm { 
    display: none
}
  }

.Navigation_topBar__wiQEm svg {
  height: 1em;
}

.Navigation_logo___MyZn{
  width: 100%;
}

.Navigation_logoContainer__cnwFh {
  width: 218px;
}

.Navigation_topBarContainer__MRlgn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

@media screen and (max-width: 767px) {

.Navigation_topBarContainer__MRlgn { 
    justify-content: space-between;
    padding: 0 15px
}
  }

.Navigation_navigationWrapper__AUI3F{
  background-color: transparent;
  transition: background-color 350ms;
  position: fixed;
  z-index: 100;
  width: 100%;
}

.Navigation_navigationWrapperOpen__XOuNz{
  background-color: var(--color-red);
  transition: background-color 350ms;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

@media screen and (max-width: 992px) {
  .mobileMenuOpen > .Navigation_navigationWrapper__AUI3F {
    background-color: var(--color-red);
  }
}

.Navigation_topBarButton__fkvFu{
  color: white;
}

@media screen and (max-width: 767px) {
    .Navigation_topBarButton__fkvFu > span {
      display: none;
    }
  }

.Navigation_topColorChange__g5rlp{
  background-color: var(--color-red-10);
  transition: background-color 350ms;
}

.Navigation_topBarContainer__MRlgn a {
  display: flex;
  align-items: center;
  flex-direction: row;
  color: white;
  gap: 5px;
}

.Navigation_navigation__3lYVo {
  position: relative;
  z-index: 2;
}


.Navigation_alertDialogContent__xr3Iq{
  border-radius: 5px;
}




/* Phone Number */

.Navigation_contact-card__q6EJ8 {
  position: relative;
  max-width: 87rem;
  width: 100%;
  padding: 2rem;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {

.Navigation_contact-card__q6EJ8 {
    width: 57rem
}
  }

.Navigation_close-button__57xOg {
  position: absolute;
  right: 1rem;
  top: 1rem;
  background: none;
  border: none;
  cursor: pointer;
}

.Navigation_close-icon__JYm7W {
  height: 1.5rem;
  width: 1.5rem;
  color: #6b7280;
}

.Navigation_contact-grid__ExetL {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

@media (min-width: 992px) {

.Navigation_contact-grid__ExetL {
    grid-template-columns: 1fr 1fr
}
  }

.Navigation_contact-section__JD4wT,
.Navigation_hours-section__azhzS {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.Navigation_section-title__Y1lJF {
  font-size: 1.5rem;
  font-weight: 500;
  color: initial;
  margin-bottom: 1rem;
}

.Navigation_content-wrapper__nUmxU {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.Navigation_contact-block__9gfG5,
.Navigation_hours-block__QnkiA {
  margin-bottom: 1.5rem;
}

.Navigation_block-title__aKgIu {
  font-size: 1.125rem;
  font-weight: 500;
  color: initial;
  margin-bottom: 0.5rem;
}

.Navigation_info-text__zGrIT {
  font-size: 0.875rem;
  margin-bottom: 1rem;
  color: #4b5563;
}

.Navigation_phoneNumber__oGZqF{
  color: white;
  font-size: 25px;
  margin-top: 0;
  text-align: right;
}

.Navigation_sales-opening-times__NJXo8{
  font-size: 11px;
  width: 200px;
  text-align: right;
  color: white;
}

.Navigation_action-button__78bhG {
  background-color: #dc2626;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.Navigation_action-button__78bhG:hover {
  background-color: #b91c1c;
}

.Navigation_hours-table__3BkrG {
  width: 100%;
  border-collapse: collapse;
}

.Navigation_hours-table__3BkrG th {
  text-align: left;
  padding: 0.5rem 0;
  font-weight: 600;
}

.Navigation_hours-table__3BkrG td {
  padding: 0.5rem 0;
  border-top: 1px solid #e5e7eb;
}

.Navigation_hours-table__3BkrG tr:first-child td {
  border-top: none;
}

.Navigation_smartCodeModalContent__6hF0z {
  width: 440px;
  max-width: 100%;
  border-radius: 5px;
}

.Navigation_smartCodeModalContentTop__89ZFL {
  padding: 30px 30px 0;
}

.Navigation_smartCodeModalContentTop__89ZFL>h3 {
    margin-bottom: 30px;
  }

.Navigation_smartCodeModalFooter__j_kOK {
  padding: 30px 30px 30px;
  background-color: var(--color-grey-light);
}

.Navigation_smartCodeDescription__2M12K {
  font-size: 12px;
}

.Navigation_smartCodeInput__IeVj3 {
  display: block;
  padding: 0.5em;
  border: 1px solid var(--color-grey);
  font-family: monospace;
  letter-spacing: 0.1em;
  width: 100%;
  text-align: center;
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  transition: border-color 0.2s ease;
}

.Navigation_smartCodeInput__IeVj3.Navigation_empty__roOWG {
  border-color: #ccc;
}

.Navigation_smartCodeInput__IeVj3.Navigation_valid__AE5TJ {
  border-color: #4CAF50;
  color: #4CAF50;
}

.Navigation_smartCodeInput__IeVj3.Navigation_invalid__0fSlu {
  border-color: #f44336;
  color: #f44336;
}

.Navigation_smartCodeSubmit__98Iu6 {
  width: 100%;
  margin-bottom: 30px;
}

.Navigation_alertDialogClose__2Du1h{
  position: absolute;
  top: 0;
  right: 0;
  padding: 30px;
  cursor: pointer;
}

.Navigation_alertDialogCloseIcon__3zwhN{
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));
}

.Navigation_alertDialogClose__2Du1h:hover{
  background-color: transparent;
}

/* AlertDialog.css */

.AlertDialog_alert-dialog-content__ZLQbp {
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 100%;
}

.AlertDialog_alert-dialog-content-without-backdrop__NYiFW {
  border: none;
  height: 720px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.AlertDialog_alert-dialog-content__ZLQbp::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.AlertDialog_alert-dialog-header__PcZmA {
  margin-bottom: 1rem;
}

.AlertDialog_alert-dialog-footer__EmTQR {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 1rem;
}

.AlertDialog_alert-dialog-title__yMBr3 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.AlertDialog_alert-dialog-description__mIKg7 {
  color: #666;
  line-height: 1.5;
}

.AlertDialog_alert-dialog-action__UiFQw {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background-color: var(--color-red);
  color: white;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.AlertDialog_alert-dialog-action__UiFQw:hover {
  background-color: var(--color-red);
  opacity: 0.8;
}

.AlertDialog_alert-dialog-cancel__TT1mr {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background-color: white;
  color: #333;
  border: 1px solid #e5e7eb;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.AlertDialog_alert-dialog-cancel__TT1mr:hover {
  background-color: #f3f4f6;
}

/* Button variants */
.AlertDialog_button__K9vxt {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s;
}

.AlertDialog_button-default__Lqsw0 {
  background-color: #3b82f6;
  color: white;
  border: none;
}

.AlertDialog_button-default__Lqsw0:hover {
  background-color: #2563eb;
}

.AlertDialog_button-outline__LDXHG {
  background-color: white;
  color: #333;
  border: 1px solid #e5e7eb;
}

.AlertDialog_button-outline__LDXHG:hover {
  background-color: #f3f4f6;
}

.AlertDialog_button-destructive__aboyW {
  background-color: #ef4444;
  color: white;
  border: none;
}

.AlertDialog_button-destructive__aboyW:hover {
  background-color: #dc2626;
}

/* Focus states */
.AlertDialog_button__K9vxt:focus,
.AlertDialog_alert-dialog-action__UiFQw:focus,
.AlertDialog_alert-dialog-cancel__TT1mr:focus {
  outline: 2px solid var(--color-red);
  outline-offset: 2px;
}

/* Animation for dialog */
@keyframes AlertDialog_fadeIn__cbIFW {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.AlertDialog_alert-dialog-content__ZLQbp[open] {
  animation: AlertDialog_fadeIn__cbIFW 0.1s ease-out;
}

.AlertDialog_alert-dialog-content-without-backdrop__NYiFW[open] {
  animation: AlertDialog_fadeIn__cbIFW 0.1s ease-out;
}
.SignupModal_modal__y9LfG {
  border: none;
  padding: 0;
  margin: auto;
  width: 900px;
  height: 700px;
  max-width: 100vw;
  max-height: 100vh;
  background: white;
  position: fixed;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.2);
}

.SignupModal_modal__y9LfG::backdrop {
  background: rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 768px) {
  .SignupModal_modal__y9LfG {
    height: 600px;
  }
}

.SignupModal_container__6vjWB {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.SignupModal_background__M9qAL,
.SignupModal_backgroundMobile__DOW8z {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  user-select: none;
}

.SignupModal_background__M9qAL,
.SignupModal_foreground__iUNtG {
  display: none;
}

.SignupModal_backgroundMobile__DOW8z,
.SignupModal_foregroundMobile__OZvYS {
  display: block;
}

@media screen and (min-width: 768px) {
  .SignupModal_background__M9qAL,
  .SignupModal_foreground__iUNtG {
    display: block;
  }

  .SignupModal_backgroundMobile__DOW8z,
  .SignupModal_foregroundMobile__OZvYS {
    display: none;
  }
}

.SignupModal_foreground__iUNtG,
.SignupModal_foregroundMobile__OZvYS {
  max-width: calc(100% - 60px);
  margin-top: 60px;
  user-select: none;
}

.SignupModal_formContainer__0UFI1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  width: 440px;
  max-width: 100%;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .SignupModal_formContainer__0UFI1 {
    left: 50%;
  }
}

.SignupModal_formContainer__0UFI1 form {
  padding: 30px;
}

@media screen and (min-width: 480px) {
  .SignupModal_formContainer__0UFI1 form {
    padding: 30px 60px;
  }
}

.SignupModal_input__7qhzW {
  font-size: 18px;
  width: 100%;
  height: 48px;
  margin-bottom: 10px;
  border: 0;
  padding: 10px;
  text-align: center;
  font-weight: 700;
  letter-spacing: 1.2px;
  color: #003366;
}

@media screen and (min-width: 992px) {
  .SignupModal_input__7qhzW {
    margin-bottom: 20px;
    height: 64px;
  }
}

.SignupModal_submit__vHYeX {
  background-color: #e60012;
  width: 100%;
  height: 64px;
  color: white;
  font-family: inherit;
  letter-spacing: 1.2px;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  border-radius: 5px;
  transition: box-shadow 0.2s;
  box-shadow: 0 0 0 rgba(181, 1, 10, 0);
  border: 1px solid white;
}

.SignupModal_submit__vHYeX:hover {
  box-shadow: 0 0 14px rgb(135, 0, 7);
  border: 1px solid #e60012;
}

.SignupModal_close__mrs54 {
  font-size: 16px;
  height: 32px;
  border-radius: 5px;
  line-height: 32px;
  display: block;
  text-align: center;
  cursor: pointer;
  background-color: white;
  color: #e60012;
  padding: 0 10px;
  width: 100%;
  border: none;
}

.SignupModal_centered__mYnDg {
  left: 0;
  right: 0;
  margin: auto;
  width: 440px;
  max-width: 100%;
}

@media screen and (min-width: 992px) {
  .SignupModal_centered__mYnDg {
    left: 20%;
    right: 20%;
  }
}

.SignupModal_hidden__DRch9 {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.PackageIcon_wrapper__6DgTp {
  position: relative;
  width: 64px;
  height: 64px;
}

.PackageIcon_image__gWI1D {
  object-fit: contain !important;
}

.FromPrice_container__0QW4C {
  display: flex;
  gap: 5px;
  align-items: flex-start;
  font-size: 64px;
}

.FromPrice_from__qqknF {
  font-family: var(--header-font);
  text-transform: uppercase;
  letter-spacing: 0.05em;
  line-height: 1em;
  font-size: 0.27em;
}

.FromPrice_price__7_lWU {
  font-size: 64px;
  line-height: 0.75em;
  color: var(--color-red);
  font-family: var(--header-font);
  font-weight: 900;
}

.FromPrice_PP__D1QIs {
  font-family: var(--header-font);
  align-self: flex-end;
  letter-spacing: 0.05em;
  line-height: 1em;
  font-size: 0.27em;
}

.FromPrice_starbuy__VJQ_g .FromPrice_price__7_lWU {
    background: var(--color-star-buy-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
.PriceGrid_priceGridContainer__NEUr5 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.PriceGrid_alternativeDropdowns__C4iWq {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
}

.PriceGrid_dropdownContent__KuDSU {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  font-size: 15px;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-medium);
  border-radius: 3px;
}

.PriceGrid_dropdownContent__KuDSU .PriceGrid_dropdownItem__XUQp1 {
    display: block;
    padding: 10px;
    line-height: 1em;
    width: 100%;
    text-align: left;
    color: var(--color-maroon);
  }

.PriceGrid_dropdownContent__KuDSU .PriceGrid_dropdownItem__XUQp1:hover {
      background-color: var(--color-grey-light);
    }

.PriceGrid_dropdownContent__KuDSU .PriceGrid_dropdownItemSelected__2i2nD {
    background-color: var(--color-red);
    color: var(--color-white);
  }

.PriceGrid_dropdownContent__KuDSU .PriceGrid_dropdownItemSelected__2i2nD:hover {
      background-color: var(--color-red-10);
    }

.PriceGrid_dropdownContentLeft__cwe6e {
  left: 0;
  max-width: 230px;
}

.PriceGrid_dropdownContentRight__817Eq {
  right: 0;
}

.PriceGrid_dropdown__Z0NbG {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  flex-direction: column;
  gap: 5px;
  max-width: 250px;
  min-width: 0;
}

.PriceGrid_dropdown__Z0NbG svg {
    font-size: 16px;
  }

.PriceGrid_dropdown__Z0NbG>label {
    display: flex;
    gap: 5px;
    line-height: 1.6em;
    font-size: 12px;
    font-family: var(--header-font);
    font-weight: 500;
    color: var(--color-grey-medium);
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

.PriceGrid_dropdownOpen__beQWB button > svg {
    transform: rotate(180deg) translateY(-2px);
  }

.PriceGrid_dropdownDateOnly__KdUGo {
  min-width: 100%;
}

.PriceGrid_dropdownButton__vdxgX {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 5px 8px;
  border: 1px solid var(--color-grey-shade);
  border-radius: 3px;
  font-size: 15px;
  letter-spacing: 0.05em;
  width: 100%;
}

.PriceGrid_dropdownButton__vdxgX > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-family: var(--header-content);
    color: var(--color-grey-deep);
    min-width: 0;
    flex: 1;
  }

.PriceGrid_dropdownButton__vdxgX > span > svg {
      margin-top: -2px;
      color: var(--color-grey-dark);
    }

.PriceGrid_dropdownButton__vdxgX > span > :last-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 0;
    }

.PriceGrid_dropdownButton__vdxgX > svg {
    margin-top: -2px;
  }

.PriceGrid_priceGrid___AvPu {
  display: flex;
  flex-direction: row;
  border: 1px solid var(--color-grey-tint);
  border-radius: 3px;
}

.PriceGrid_priceGridLabel__a_aER {
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: 12px;
  font-family: var(--header-font);
  font-weight: 500;
  color: var(--color-grey-medium);
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-bottom: -5px;
  line-height: 1.6em;
}

.PriceGrid_priceGridLabel__a_aER > svg {
    font-size: 16px;
  }

.PriceGrid_priceGridItem__0DFcf {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  padding: 10px 5px;
  padding-bottom: 15px;
  flex-grow: 1;
  flex-basis: 0;

  border-right: 1px solid var(--color-grey-light);
}

.PriceGrid_priceGridItem__0DFcf:last-child {
    border-right: none;
  }

.PriceGrid_priceGridItem__0DFcf label {
    font-size: 14px;
    font-family: var(--header-font);
    font-weight: 500;
    text-transform: uppercase;
    color: var(--color-maroon);
    letter-spacing: 0.05em;
  }

.PriceGrid_priceGridItem__0DFcf>svg {
    font-size: 1.5em;
  }


.PriceGrid_priceGridItemPrice__dmKuO {
  font-size: 24px;
  font-family: var(--header-font);
  font-weight: 600;
  text-transform: uppercase;
  color: var(--color-red);
  letter-spacing: 0.05em;
}

.PriceGrid_priceGridItemSelected__4_p6j label {
    color: var(--color-red);
  }

.PriceGrid_starbuy__NDXOF .PriceGrid_priceGridItemSelected__4_p6j label {
      color: var(--color-star-buy-solid);
    }

.PriceGrid_starbuy__NDXOF .PriceGrid_priceGridItemPrice__dmKuO {
    color: var(--color-star-buy-solid);
  }

.PriceGrid_starbuy__NDXOF .PriceGrid_priceGridItemPrice__dmKuO svg {
      color: var(--color-star-buy-solid);
    }

.PriceGrid_upgradePrices__pv_aS {
  font-size: 12px;
  color: var(--color-grey-dark);
  text-align: center;
}

.PriceGrid_alternativeCabinTable__AIvBn {
  width: 100%;
  border-collapse: collapse;
}

.PriceGrid_alternativeCabinTable__AIvBn th {
    font-size: 12px;
    font-family: var(--header-font);
    font-weight: 500;
    text-transform: uppercase;
    color: var(--color-grey-medium);
    letter-spacing: 0.05em;
    
  }

.PriceGrid_alternativeCabinTable__AIvBn td, .PriceGrid_alternativeCabinTable__AIvBn th {
    padding: 15px 5px;
  }

.PriceGrid_alternativeCabinTable__AIvBn td:first-child, .PriceGrid_alternativeCabinTable__AIvBn th:first-child {
      padding-left: 10px;
    }

.PriceGrid_alternativeCabinTable__AIvBn td:last-child, .PriceGrid_alternativeCabinTable__AIvBn th:last-child {
      padding-right: 10px;
    }

.PriceGrid_alternativeCabinTable__AIvBn td {
    text-align: center;
  }

.PriceGrid_alternativeCabinTableRow__mGQ6F {
  cursor: pointer;
}

.PriceGrid_alternativeCabinTableRow__mGQ6F:hover {
    background-color: var(--color-grey-light);
  }

.PriceGrid_alternativeCabinTableRowSelected__WNdzG {
  background-color: var(--color-red);
  color: var(--color-white);
}

.PriceGrid_alternativeCabinTableRowSelected__WNdzG:hover {
    background-color: var(--color-red-10);
  }

.PriceGrid_dropdownItemContent__pevrh {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.PriceGrid_dropdownItemPrice__0cFF7 {
  font-size: 0.9em;
  color: var(--color-text-secondary);
  margin-left: 1rem;
}



.PricePerNight_pricePerNight__J7sXo {
  font-size: 16px;
  font-family: var(--font-content);
  font-weight: 500;
  color: var(--color-maroon);
  margin-top: -20px;
  margin-bottom: -10px;
  text-align: right;
}

.PricePerNight_starbuy__Vyesu {
  color: var(--color-star-buy-solid);
  font-weight: 600;
  font-family: var(--header-font);
}

.MapBox_popUp__5xWey{
  width: 1280px;
  height: 720px;
  cursor: initial;
  max-width: 100%;
}

.MapBox_alertDialogMain__WyPAY {
  width: 100%;
  height: 100%;
  position: relative;
}

.MapBox_overlay___jkLP {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent linear-gradient(90deg, #00000087 0%, #0000006E 40%, #00000000 60%, #00000000 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.MapBox_higlightBackgroundOverlay__PKLOb{
  border-radius: none;
}

.MapBox_alertDialogInformation__DE7Mi{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 30px;
}

@media screen and (min-width: 768px) {

.MapBox_alertDialogInformation__DE7Mi{
    padding: 60px
}
  }

.MapBox_highlightImage__ys6q5 {
  position: relative;
  width: 100%;
  height: 100%;
}

.MapBox_highlightDialogTitle__ku_U7{
  font-size: 42px;
  font-weight: 500;
  font-family: var(--header-font);
  margin-bottom: 10px;
  color: white;
  text-transform: uppercase;
}

.MapBox_alertSubTitle__d7e9_ {
  font-size: 14px;
  font-weight: 500;
  font-family: var(--header-font);
  color: white;
  text-transform: uppercase;
}

.MapBox_alertDescription__ToPST{
  width: 399px;
  max-width: 100%;
}

.MapBox_alertDescription__ToPST>p{
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5rem;
  color: white;
  padding-top: 0;
  font-family: var(--body-font);
  text-shadow: 0px 3px 6px #000000BA;
}

.MapBox_alertDescription__ToPST>p:first-of-type {
  margin-top: 30px;
}

.MapBox_alertDialogClose__hSiwO{
  position: absolute;
  top: 20px;
  color: white !important;
  right: 30px;
  cursor: pointer;
}

@media screen and (min-width: 768px) {

.MapBox_alertDialogClose__hSiwO{
    right: 60px;
    top: 60px
}
  }

.MapBox_alertDialogCloseIcon__N_58U{
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));
}

.MapBox_alertDialogClose__hSiwO:hover{
  background-color: transparent !important;
}

.MapBox_alertDialogBtn__TR2qg {
  position: absolute;
  bottom: 50px;
  min-width: 200px;
  color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  right: 60px;
  cursor: pointer;
}

@media screen and (max-width: 620px) {

.MapBox_alertDialogBtn__TR2qg {
      right: 50%;
      min-width: 80%;
      transform: translateX(50%)
}
  }
.Header_main__zc_sM {
    width: 100%;
    height: fit-content;
    position: relative;
    max-height: 100vh;
}

.Header_overlay__HOxss {
    width: 100%;
    height: 100%;
    position: absolute;
    background: transparent linear-gradient(180deg, #ABABAB 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
    opacity: 1;
    top: 0;
    left: 0;
}

.Header_overlayShort__xG60s {
    width: 100%;
    height: 100%;
    position: absolute;
    background: transparent linear-gradient(180deg, #5c5b5b 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
    opacity: 1;
    top: 0;
    left: 0;
}


.Header_title___VO4J {
    color: white;
    font-weight: 400;
    margin-top: 10px;
    font-family: var(--header-font);
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom: 10px;
    text-align: left;
    justify-content: left;
    margin-left: -4px;
}

.Header_statIconStyle__DJm7e{
    filter: invert(1) brightness(100);
}

.Header_stats__d_TtX{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 18px;
    background-color: var(--color-red-10);
    width: fit-content;
    padding: 10px;
}

.Header_label__Ly2EB{
    font-size: 14px;
    color: white;
    font-weight: 500;
    font-family: var(--header-font);
    letter-spacing: 0.2em;
    text-transform: uppercase;
    text-align: left;
    justify-content: left;
}

.Header_statItem__PHc7H{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 5px;
}

.Header_statImage__aD9T3{
    position: relative;
    width: 14px;
    height: 14px;
}

.Header_subtitle__0xkBf {
    font-size: 18px;
    color: white;
    font-weight: 500;
    margin-top: 5%;
    font-family: var(--header-font);
    letter-spacing: 0.2em;
    text-transform: uppercase;
    text-align: left;
    justify-content: left;
}

.Header_headerScrollButtonHolder__JjwU3 {
    width: 100%;
    height: 80px;
    margin-top: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    animation: Header_bounce__oriJf 1s infinite;
}

.Header_statsVisible__zcnRE {
    margin-top: 110px;
}

.Header_headerScrollButton___ZDlF {
    margin-top: 12px;
    cursor: pointer;
    transition: 500ms;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.Header_headerScrollButtonTitle__cSkYv {
    font-size: 15px;
    color: white;
    font-weight: 200;
    font-family: var(--header-font);
    letter-spacing: 0.2em;
    text-transform: uppercase;
    text-align: left;
    justify-content: left;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.Header_headerScrollButton___ZDlF:hover {
    transform: translateY(5px);
}

.Header_imageWrapper___dq6t {
    width: 100%;
    height: 800px;
}

@media screen and (max-width: 991px) {

.Header_imageWrapper___dq6t {
        min-height: 100vh;
        height: auto;
}
    }

.Header_imageWrapper___dq6t {
    position: relative;
}

.Header_imageWrapperMedium__Hemde {
    width: 100%;
    height: 400px;
}

@media screen and (max-width: 991px) {

.Header_imageWrapperMedium__Hemde {
        height: 400px;
}
    }

.Header_imageWrapperMedium__Hemde {
    position: relative;
}

.Header_imageWrapperShort__o2uYd {
    width: 100%;
    height: 100px;
    position: relative;
}

.Header_container__OhGPl {
    position: absolute;
    top: 50%;
    margin: auto;
    left: 0;
    right: 0;
}

.Header_searchContainer__dFm1I {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    z-index: 1;
}

/* Fix: z-index issue with mobile search not overlapping navigation and other header elements */

.Header_searchContainer__dFm1I:has([class*="advancedRegionMobileOpen"]) {
        z-index: 100;
    }

@keyframes Header_bounce__oriJf {
    0%, 100% {
      transform: translateY(-25%);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: translateY(0);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
.ContentSplitTwoImages_outterContainer__XS3V2 {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.ContentSplitTwoImages_title__W4x9V {
  margin-top: calc(-0.2em + 30px);
}

.ContentSplitTwoImages_container__hMOzY {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media screen and (min-width: 768px) {

.ContentSplitTwoImages_container__hMOzY {
    flex-direction: row
}
  }

.ContentSplitTwoImages_container__hMOzY:first-child {
    z-index: 2;
  }

.ContentSplitTwoImages_container__hMOzY:first-child .ContentSplitTwoImages_textContent__0LPwA {
      padding-bottom: 10px;
    }

@media screen and (min-width: 768px) {

.ContentSplitTwoImages_container__hMOzY .ContentSplitTwoImages_imageContainer__whMOx {
      border-left: 30px solid white;
      border-bottom: 30px solid white
  }
    }

@media screen and (min-width: 768px) {

.ContentSplitTwoImages_container__hMOzY:nth-child(2) .ContentSplitTwoImages_imageContainer__whMOx {
        position: absolute;
        bottom: 0;
        top: initial;
        border-bottom: 0;
        height: 100%;
        right: 0;
        left: initial
    }
      }

@media screen and (min-width: 768px) {

.ContentSplitTwoImages_container__hMOzY:nth-child(2) .ContentSplitTwoImages_imageContainer__whMOx.ContentSplitTwoImages_imageContainerWide__ENSas {
        left: 0;
        right: initial
    }
      }

@media screen and (min-width: 768px) {

.ContentSplitTwoImages_container__hMOzY:nth-child(2) .ContentSplitTwoImages_textContent__0LPwA {
        padding-top: 170px;
        padding-left: 0;
        padding-bottom: 60px
    }
      }

.ContentSplitTwoImages_imageContent__Ex05R {
  position: relative;
  flex-grow: 1;
  min-width: calc(55% + 15px);
  transform: translateX(0);
}

.ContentSplitTwoImages_text__kR6rB {
  max-width: 100%;
  width: 450px;
  margin-bottom: 1.5em;
  min-height: 140px;
}

.ContentSplitTwoImages_containerFlipped__canLv {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media screen and (min-width: 768px) {

.ContentSplitTwoImages_containerFlipped__canLv {
    flex-direction: row-reverse
}
  }

.ContentSplitTwoImages_containerFlipped__canLv:first-child {
    z-index: 2;
  }

.ContentSplitTwoImages_containerFlipped__canLv:first-child .ContentSplitTwoImages_textContent__0LPwA {
      padding-bottom: 10px;
    }

@media screen and (min-width: 768px) {

.ContentSplitTwoImages_containerFlipped__canLv:first-child .ContentSplitTwoImages_imageContainer__whMOx.ContentSplitTwoImages_imageContainerWide__ENSas {
        right: 0;
        left: initial
    }
      }

.ContentSplitTwoImages_containerFlipped__canLv .ContentSplitTwoImages_imageContent__Ex05R {
    transform: translateX(0);
  }

.ContentSplitTwoImages_containerFlipped__canLv:nth-child(2) .ContentSplitTwoImages_imageContainer__whMOx {
      position: relative;
      left: 0;
    }

@media screen and (min-width: 768px) {

.ContentSplitTwoImages_containerFlipped__canLv:nth-child(2) .ContentSplitTwoImages_imageContainer__whMOx {
        position: absolute;
        bottom: 0;
        top: initial;
        border-bottom: 0;
        height: 100%;
        right: 0;
        left: initial;
        border-left: 0
    }
      }

@media screen and (min-width: 768px) {

.ContentSplitTwoImages_containerFlipped__canLv:nth-child(2) .ContentSplitTwoImages_textContent__0LPwA {
        padding-top: 200px;
        padding-left: 0;
        padding-bottom: 60px
    }
      }

@media screen and (min-width: 768px) {

.ContentSplitTwoImages_containerFlipped__canLv .ContentSplitTwoImages_imageContainer__whMOx {
      border-right: 30px solid white;
      border-bottom: 30px solid white
  }
    }


.ContentSplitTwoImages_imageContainer__whMOx {
  position: relative;
  width: calc(100% - 30px);
  height: 250px;
  left: 30px;
}


@media screen and (min-width: 768px) {


.ContentSplitTwoImages_imageContainer__whMOx {
    position: absolute;
    width: 100%;
    height: calc(100% + 200px);
    left: 0;
    top: 0
}
  }

@media screen and (min-width: 768px) {

.ContentSplitTwoImages_imageContainerWide__ENSas {
    width: calc((100vw / 2) + 86px)
}
  }

.ContentSplitTwoImages_textContent__0LPwA {
  flex-grow: 1;
  min-width: calc(33.33% - 30px);
}

.ContentSplitTwoImages_imageDescription__pDwVK {
  position: absolute;
  left: -3px;
  transform: rotate(90deg);
  transform-origin: top left;
  color: #666;
  font-size: 16px;
}

.ContentSplitTwoImages_imageDescriptionFlipped__NiLge {
  position: absolute;
  right: -3px;
  transform: rotate(90deg);
  transform-origin: bottom right;
  color: #666;
  font-size: 16px;
}

@media screen and (min-width: 768px) {

.ContentSplitTwoImages_imageDescriptionFlipped__NiLge {
    right: -3px;
    transform: rotate(90deg) translateX(100%);
    transform-origin: bottom right
}
  }

.ContentSplitTwoImages_container__hMOzY:first-child > div > figure > figcaption {
    top: 0;
  }

.ContentSplitTwoImages_containerFlipped__canLv:first-child > div > figure > figcaption {
    top: -20px;
  }

.ContentSplitTwoImages_container__hMOzY:nth-child(2) > div > figure > figcaption {
    top: 175px;
  }

.ContentSplitTwoImages_containerFlipped__canLv:nth-child(2) > div > figure > figcaption {
    top: 112px;
  }

@media screen and (min-width: 768px) {

.ContentSplitTwoImages_containerFlipped__canLv:nth-child(2) > div > figure > figcaption {
      top: 148px
  }
    }

/* Fix for quote text not sitting in the correct position on mobile */

@media screen and (max-width: 767px) {

.ContentSplitTwoImages_containerFlipped__canLv:nth-child(2) > div > figure > figcaption {
      transform: translateY(42px) rotate(90deg)
  }
    }
.ContentTextImageSurround_topSection__lnro7 {
  display: flex;
  align-items: stretch;
  flex-direction: column-reverse;
  gap: 30px;
}
  
  @media screen and (min-width: 768px) {.ContentTextImageSurround_topSection__lnro7 {
    flex-direction: row
}
  }

.ContentTextImageSurround_topImage__Qooqa {
  flex-grow: 1;
  min-width: calc(55% + 15px);
  position: relative;
}

@media screen and (max-width: 767px) {

.ContentTextImageSurround_topImage__Qooqa {
    height: 500px
}
  }

@media screen and (min-width: 768px) {

.ContentTextImageSurround_topImage__Qooqa {
    border-right: 30px solid white
}
  }

@media screen and (min-width: 768px) {

.ContentTextImageSurround_topSectionFlipped__Dkp6R {
    flex-direction: row-reverse
}
  }

.ContentTextImageSurround_topSectionFlipped__Dkp6R .ContentTextImageSurround_topImage__Qooqa {
    border-right: 0;
    border-left: 0;
  }

@media screen and (min-width: 768px) {

.ContentTextImageSurround_topSectionFlipped__Dkp6R .ContentTextImageSurround_topImage__Qooqa {
      border-left: 30px solid white
  }
    }

.ContentTextImageSurround_text__ayzou{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5em;
}

.ContentTextImageSurround_textSection__ApRlS {    
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-width: calc(33.33% - 30px);
}

@media screen and (min-width: 768px) {

.ContentTextImageSurround_textSection__ApRlS {
    padding: 60px 0 200px;
    min-height: 700px
}
  }

.ContentTextImageSurround_bottomSection__MEQHe {
  display: flex;
  height: 160px;
  align-items: stretch;
  flex-direction: row-reverse;
}

@media screen and (min-width: 768px) {

.ContentTextImageSurround_bottomSection__MEQHe {
    height: 300px
}
  }

.ContentTextImageSurround_bottomSideImage__QzKxE {
  position: relative;
  flex-grow: 1;
  min-width: calc(66.66% + 150px);
  margin-right: -150px;
  margin-top: -150px;
  margin-bottom: 100px;
  border-left: 15px solid white;
  border-top: 15px solid white;
}

@media screen and (min-width: 768px) {

.ContentTextImageSurround_bottomSideImage__QzKxE {
    border-left: 30px solid white;
    border-top: 30px solid white;
    margin-bottom: 150px
}
  }

.ContentTextImageSurround_bottomImage__ahgrm {
  position: relative;
  flex-grow: 1;
  min-width: calc(33.33%);
  margin-left: -15px;
  margin-right: -60px;
  border-top: 15px solid white;
  border-right: 15px solid white;
}

@media screen and (min-width: 768px) {

.ContentTextImageSurround_bottomImage__ahgrm {
    margin-left: -150px;
    border-top: 30px solid white;
    border-right: 0;
    margin-right: 0
}
  }

.ContentTextImageSurround_bottomSectionFlipped__bYoyF {
  flex-direction: row;
}

.ContentTextImageSurround_bottomSectionFlipped__bYoyF .ContentTextImageSurround_bottomSideImage__QzKxE {
    margin-left: -150px;
    margin-right: 0;
    border-left: 0;
    border-right: 15px solid white;
  }

@media screen and (min-width: 768px) {

.ContentTextImageSurround_bottomSectionFlipped__bYoyF .ContentTextImageSurround_bottomSideImage__QzKxE {
      border-right: 30px solid white
  }
    }

.ContentTextImageSurround_bottomSectionFlipped__bYoyF .ContentTextImageSurround_bottomImage__ahgrm {
    margin-right: -60px;
    margin-left: -15px;
    border-left: 15px solid white;
  }

@media screen and (min-width: 768px) {

.ContentTextImageSurround_bottomSectionFlipped__bYoyF .ContentTextImageSurround_bottomImage__ahgrm {
      margin-right: -150px;
      margin-left: 0;
      border-left: 0
  }
    }
.Header_main__Y_Gjt {
  width: 100%;
  height: fit-content;
  position: relative;
}

.Header_overlay__bTzwL {
  width: 100%;
  height: 100%;
  position: absolute;
  background: transparent linear-gradient(180deg, #ABABAB 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  mix-blend-mode: multiply;
  opacity: 1;
  top: 0;
  left: 0;
}

.Header_overlayShort__jz6Uv {
  width: 100%;
  height: 100%;
  position: absolute;
  background: transparent linear-gradient(180deg, #5c5b5b 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  mix-blend-mode: multiply;
  opacity: 1;
  top: 0;
  left: 0;
}


.Header_title__XsoX7 {
  color: white;
  font-weight: 400;
  margin-top: 10px;
  font-family: var(--header-font);
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 10px;
  text-align: left;
  justify-content: left;
  margin-left: -4px;
}


@media screen and (max-width: 1044px) {


.Header_title__XsoX7 {
    font-size: 25px;
    white-space: initial
}
  }

.Header_statIconStyle__CuUAB {
  filter: invert(1) brightness(100);
}

.Header_stats__9EVrW {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
}

.Header_label__P2HyO {
  font-size: 14px;
  color: white;
  font-weight: 500;
  font-family: var(--header-font);
  letter-spacing: 0.2em;
  text-transform: uppercase;
  text-align: left;
  justify-content: left;
}

.Header_statItem__paSDd {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
}

.Header_statImage__s8r1X {
  position: relative;
  width: 14px;
  height: 14px;
}

.Header_subtitle__hyct3 {
  font-size: 18px;
  color: white;
  font-weight: 500;
  margin-top: 10px;
  font-family: var(--header-font);
  letter-spacing: 0.2em;
  text-transform: uppercase;
  text-align: left;
  justify-content: left;
}

.Header_headerScrollButtonHolder__7uhIA {
  width: 100%;
  height: 80px;
  margin-top: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Header_statsVisible__OTV20 {
  margin-top: 110px;
}

.Header_headerScrollButton__2D9Vz {
  margin-top: 12px;
  cursor: pointer;
  transition: 500ms;
}

.Header_headerScrollButtonTitle__em1j8 {
  font-size: 15px;
  color: white;
  font-weight: 200;
  font-family: var(--header-font);
  letter-spacing: 0.2em;
  text-transform: uppercase;
  text-align: left;
  justify-content: left;
}

.Header_headerScrollButton__2D9Vz:hover {
  transform: translateY(5px);
}

.Header_imageWrapper__TTM4W {
  width: 100%;
  height: 500px;
  position: relative;
}

.Header_imageWrapperShort__O2W3U {
  width: 100%;
  height: 200px;
  position: relative;
}

.Header_search__3wM0q {
  height: 100%;
  width: 100%;
}

.Header_mainSearch__BTA3C {
  width: 50%;
  margin-top: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: row;
}

@media screen and (max-width: 1144px) {

.Header_mainSearch__BTA3C {
    width: 70%;
    flex-direction: column;
    align-items: start
}
  }

@media screen and (max-width: 578px) {

.Header_mainSearch__BTA3C {
    width: 100%
}
  }

.Header_searchButton__N6FXi {
  display: flex;
  align-items: center;
  font-weight: 500;
  height: 40px;
  gap: 10px;
}

.Header_container__lgNYs {
  position: absolute;
  top: 32%;
  margin: auto;
  left: 0;
  right: 0;
}





.Header_searchFilterItem__Exgnj {
  width: 100%;
  text-align: start;
  padding: 15px;
  gap: 10px;
  border: none;
  background-color: transparent;
  text-transform: uppercase;
  color: #37444d;
  border-top: 1px solid #dbfdff;
  font-size: 13px;
  font-family: var(--header-font);
  letter-spacing: 1px;
  font-weight: 600;
}

.Header_searchFilterItem__Exgnj>p {
  font-weight: initial;
  text-transform: initial;
  font-size: 13px;
  font-family: var(--body-font);
}

.Header_searchFilterItem__Exgnj>svg {
  font-size: 1em;
  min-width: 20px;
  margin-right: 10px;
  text-align: left;
  color: #f4081f;
}

/* &:first-child .searchFilterItem {
  border-top: none;
} */
.TextField_searchableInputContainer__OTd7F {
  position: relative;
  min-width: 10%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.TextField_searchableInput__Z1CEf {
  border: none;
  padding: 9px 12px;
  font-size: 16px;
  font-family: var(--body-font);
  width: 100%;
  height: 100%;
  background-color: inherit;
}

.TextField_searchableInputMain__xoGCi{
  width: 100%;
  border-radius: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.TextField_searchableInputMainHasIcon__yq1g9 {
  position: relative;
}

.TextField_searchableInputMainHasIcon__yq1g9>svg {
    position: absolute;
    left: 12px;
    top: 12px;
    user-select: none;
    pointer-events: none;
  }

.TextField_searchableInputMainHasIcon__yq1g9 .TextField_searchableInput__Z1CEf {
    padding-left: 40px;
  }

.TextField_searchableInput__Z1CEf:focus{
  outline: none;
}

.TextField_searchableInput__Z1CEf::placeholder{
  color: rgb(22, 22, 20);
  font-weight: 5800;
  font-size: 15px;
}

.TextField_searchableDropdown__wDLz4 {
  position: absolute;
  top: 92%;
  left: -1px;
  right: 1px;
  width: 100.3%;
  max-height: 400px;
  overflow-y: auto;
  background-color: inherit;
  border: inherit;
  z-index: 10;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  border-top: none;
  box-shadow: inherit;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.TextField_searchableDropdownItem__5pnPE {
  cursor: pointer;
}

.TextField_searchableDropdownItem__5pnPE:hover {
  background-color: #f0f0f0;
}
.Categories_categories__AKYEk {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

    @media screen and (max-width: 1125px) {.Categories_categories__AKYEk {
        grid-template-columns: repeat(3, 1fr)
}
    }

    @media screen and (max-width: 987px) {.Categories_categories__AKYEk {
        grid-template-columns: repeat(2, 1fr)
}
    }

    @media screen and (max-width: 755px) {.Categories_categories__AKYEk {
        grid-template-columns: repeat(1, 1fr)
}
    }

.Categories_articles__rdU0g {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.Categories_category__b65vD {
    display: flex;
    height: 220px;
    flex-direction: column;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid #f4081f;
    padding: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #fff;
    outline: 4px solid #fff;
}

@media screen and (max-width: 755px) {

.Categories_category__b65vD {
        height: 150px
}
    }

.Categories_article__r98fJ {
    display: flex;
    height: 100px;
    width: 310px;
    border-radius: 5px;
    border: 1px solid #f4081f;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #fff;
    outline: 4px solid #fff;
}

.Categories_category_icon__L4pXi {
    width: 20px;
    height: 20px;
    position: relative;
}

.Categories_category_icon__L4pXi img {
    filter: brightness(0) saturate(100%) invert(32%) sepia(95%) saturate(4237%) hue-rotate(348deg) brightness(91%) contrast(107%);
}

.Categories_category__b65vD h4 {

    text-align: center;
    font-weight: 500;
    color: #303030;
}

@media screen and (max-width: 755px) {

.Categories_category__b65vD h4 {
        font-size: 15px
}
    }

.Categories_article__r98fJ h5 {
    text-align: center;
    font-weight: 500;
    color: #000000;
}

.Categories_category__b65vD:hover {
    outline: 1.2px solid #f4081f;
}
.Search_search__Ij_nI{
    height: 100%;
    width: 100%;
    border: 1px solid black;
  }
  
  .Search_mainSearch__oaaEL{
    width: 50%;
    margin-top: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    flex-direction: row;
  }
  
  @media screen and (max-width: 1044px) {
  
  .Search_mainSearch__oaaEL{
      width: 70%;
      flex-direction: column;
      align-items: start
  }
    }
  
  @media screen and (max-width: 578px) {
  
  .Search_mainSearch__oaaEL{
      width: 100%
  }
    }
  
  .Search_searchButton__2Q_Nr{
    display: flex;
    align-items: center;
    font-weight: bold;
    height: 40px;
    gap: 10px;
  }
  
  .Search_container___7VJM {
    margin: auto;
  }
  
  
  
  
  
  .Search_searchFilterItem__9dVFg {
    width: 100%;
    text-align: start;
    padding: 15px;
    gap: 10px;
    border: none;
    background-color: transparent;
    text-transform: uppercase;
    color: #37444d;
    border-top: 1px solid #dbfdff;
    font-size: 13px;
    font-family: var(--header-font);
    letter-spacing: 1px;
    font-weight: 600;
  }
  
  .Search_searchFilterItem__9dVFg > p {
    font-weight: initial;
    text-transform: initial;
    font-size: 13px;
    font-family: var(--body-font);
  }
  
  .Search_searchFilterItem__9dVFg > svg {
    font-size: 1em;
    min-width: 20px;
    margin-right: 10px;
    text-align: left;
    color: #f4081f;
  }
  
  .Search_showMore__Atm0m {
    text-align: center;
    color: #007bff;
    padding: 10px;
    margin: 0;
  }

  .Search_title__ksjre{
    font-size: 34px;
  }
  
  .Search_showMore__Atm0m:hover {
    text-decoration: underline;
  }
.SearchResults_search__NAHcs{
  height: 100%;
  width: 100%;
  border: 1px solid black;
}

.SearchResults_mainSearch__QgAmy{
  width: 50%;
  margin-top: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: row;
}

.SearchResults_searchButton__reFZb{
  display: flex;
  align-items: center;
  font-weight: bold;
  height: 40px;
  gap: 10px;
}

.SearchResults_container__GZ0RB {
  margin: auto;
}





.SearchResults_searchFilterItem__70lYk {
  width: 100%;
  text-align: start;
  padding: 15px;
  gap: 10px;
  border: none;
  background-color: transparent;
  text-transform: uppercase;
  color: #37444d;
  border-top: 1px solid #dbfdff;
  font-size: 13px;
  font-family: var(--header-font);
  letter-spacing: 1px;
  font-weight: 600;
}

.SearchResults_searchResults__B1TgH{
  cursor: pointer;
}
 
.SearchResults_searchResults__B1TgH:hover  p,
 .SearchResults_searchResults__B1TgH:hover {
  text-decoration: underline;
}

.SearchResults_searchFilterItem__70lYk > p {
  font-weight: initial;
  text-transform: initial;
  font-size: 13px;
  font-family: var(--body-font);
}

.SearchResults_searchFilterItem__70lYk > svg {
  font-size: 1em;
  min-width: 20px;
  margin-right: 10px;
  text-align: left;
  color: #f4081f;
}

.SearchResults_title__Xzs34{
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 50px;
}

.SearchResults_showMore__N3wY1 {
  text-align: center;
  color: #007bff;
  padding: 10px;
  margin: 0;
}

.SearchResults_showMore__N3wY1:hover {
  text-decoration: underline;
}
/* Base styles */
.SearchBar_searchBar__s0o08 {
  display: grid;
  background-color: #FFF;
  border-radius: 5px;
  height: 68px;
  grid-template-columns: 80% 20%;
  gap: 0;
  width: 100%;
  max-width: 940px;
  margin: auto;
  box-shadow: 0 0 15px rgba(104, 167, 212, 0.15);
  color: var(--color-marina);
  user-select: none;
}

.SearchBar_dropdownExit__DCHRg {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.09);
  z-index: 999;
}

.SearchBar_dropdownExitOpen__Co7Ms {
  display: block;
}

.SearchBar_options__YI4TA {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;
  background-color: #FFF;
  border-radius: 5px 0 0 5px;
}

.SearchBar_options__YI4TA > :first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow: hidden;
}


.SearchBar_dropdown__eBzaw {
  display: none;
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: #FFF;
  padding: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 15px 15px rgba(73, 114, 143, 0.25);
}

.SearchBar_dropdownLinesShips__3ltud {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 20px 20px;
}

.SearchBar_dropdownDestination__48_LO {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 20px 20px;
}

.SearchBar_dropdownDropdownListOptions__0wmiR {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 5px 20px;
}

.SearchBar_listOption___Etue {
  text-align: left;
  padding: 15px 10px;
  background: transparent;
  border: 0;
  border-radius: 3px;
  line-height: 1em;
  font-size: 15px;
}

.SearchBar_listOption___Etue:hover {
  background-color: var(--color-grey-light);
}

.SearchBar_listOption___Etue:focus {
  outline: 2px solid var(--color-red-10);
}

.SearchBar_listOptionSelected__gV1hN {
  color: #FFF;
  background-color: var(--color-red-10);
  font-weight: 700;
}

.SearchBar_listOptionSelected__gV1hN:hover {
  color: #FFF;
  background-color: var(--color-red-10);
  cursor: default;
}

.SearchBar_optionButton__sEYyt {
  width: 100%;
  height: 100%;
  padding: 0;
  border: 0;
  background-color: transparent;
}

.SearchBar_searchButton__ufn2L {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: var(--color-red-10);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
  font-family: var(--font-family-header);
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: #FFF;
}

.SearchBar_option__Lsc7J {
  position: relative;
}

/* Option button styles */
.SearchBar_optionButton__sEYyt {
  display: flex;
  padding: 15px;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
}

.SearchBar_optionLabel__nDFq1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.SearchBar_optionLabel__nDFq1 i {
  font-size: 24px;
  color: var(--color-red-10);
}

.SearchBar_optionText__uVm8d {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.SearchBar_optionLabelText__dsnBV {
  font-size: 12px;
  font-family: var(--font-family-header);
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: var(--color-grey-medium);
}

.SearchBar_optionLabelValue__8MeZT {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 160px;
  font-size: 18px;
  font-family: var(--font-family-content);
  margin-top: 0;
}

.SearchBar_optionChevron__bM_9E {
  display: flex;
  height: 100%;
  align-items: flex-end;
  font-size: 20px;
  margin-bottom: 7px;
  transform-origin: 10px 36px;
  transition: transform 0.15s;
}

/* Open states */
.SearchBar_searchBarOpen__NWk7U .SearchBar_options__YI4TA {
    border-bottom-left-radius: 0;
  }

.SearchBar_searchBarOpen__NWk7U .SearchBar_optionButton__sEYyt {
  opacity: 0.5;
  background-color: #f0f0f0;
}

.SearchBar_searchBarOpen__NWk7U .SearchBar_optionButton__sEYyt i {
  color: var(--color-marina);
}

.SearchBar_searchBarOpen__NWk7U .SearchBar_options__YI4TA {
  z-index: 1000;
}

.SearchBar_searchBarOpen__NWk7U .SearchBar_dropdown__eBzaw {
  display: block;
}

.SearchBar_searchBarOpen__NWk7U .SearchBar_dropdown__eBzaw[opened-option="passengers"] {
  width: 100%;
  right: 0;
}

@media screen and (min-width: 768px) {
  .SearchBar_searchBarOpen__NWk7U .SearchBar_dropdown__eBzaw[opened-option="passengers"] {
    width: 50%;
  }
}

@media screen and (min-width: 992px) {
  .SearchBar_searchBarOpen__NWk7U .SearchBar_dropdown__eBzaw[opened-option="passengers"] {
    width: 25%;
  }
}

.SearchBar_optionOpen__JIy7O {
}

.SearchBar_optionOpen__JIy7O .SearchBar_optionButton__sEYyt,
.SearchBar_optionOpen__JIy7O .SearchBar_optionButton__sEYyt:hover {
  opacity: 1;
  background-color: #FFF;
}

.SearchBar_optionOpen__JIy7O .SearchBar_optionIcon__zhtU8 {
  color: var(--color-red-10);
}

.SearchBar_optionOpen__JIy7O .SearchBar_optionChevron__bM_9E {
  transform: rotate(180deg);
}

.SearchBar_option__Lsc7J::after {
  position: absolute;
  top: 15px;
  bottom: 15px;
  right: 0;
  width: 1px;
  content: '';
  background-color: var(--color-grey-tint);
}

.SearchBar_option__Lsc7J:nth-child(3)::after {
  display: none;
}

/* Extras section */
.SearchBar_extras__HTdvF {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  max-width: 940px;
  margin: auto;
  margin-top: 10px;
}

.SearchBar_extrasButton__sCveM {
  display: flex;
  align-items: center;
  padding: 0 15px;
  background-color: #FFF;
  box-shadow: 0 0 15px rgba(104, 167, 212, 0.15);
  border-radius: 5px;
  color: var(--color-red-10);
  font-family: var(--font-family-header);
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 12px;
  line-height: 1em;
  height: 38px;
  border: 0;
  gap: 8px;
}

.SearchBar_extrasButton__sCveM:hover {
  background-color: #fafafa;
}

.SearchBar_extrasSection__JsFOF {
  display: flex;
  flex-direction: row;
  gap: 0;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  cursor: text;
}

.SearchBar_extrasSectionValid__JcYJb input,
.SearchBar_extrasSectionValid__JcYJb input:focus {
  border: 1px solid var(--color-red-10);
  border-right: 0;
}

.SearchBar_smartCode__WVUx8 {
  height: 38px;
  padding: 0 10px;
  font-family: var(--font-family-monospace);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border-radius: 5px 0 0 5px;
  border: 1px solid var(--color-grey-tint);
  border-right: 0;
  outline: 0;
  letter-spacing: 0;
  transition: letter-spacing 0.25s;
}

.SearchBar_smartCodeHasContent__yeEiW {
  letter-spacing: 2px;
}

.SearchBar_smartCodeButton__VmUEZ {
  height: 38px;
  padding: 0 15px;
  background-color: var(--color-red-10);
  box-shadow: 0 0 15px rgba(104, 167, 212, 0.15);
  border-radius: 0 5px 5px 0;
  color: #FFF;
  font-family: var(--font-family-header);
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 12px;
  line-height: 1em;
}

.SearchBar_smartCodeButton__VmUEZ:disabled {
  border: 1px solid var(--color-grey-tint);
  border-left: 0;
  background-color: var(--color-grey-light);
  color: var(--color-grey-medium);
  pointer-events: none;
}

/* Advanced search region */
.SearchBar_advancedRegion__D10q5 {
  position: relative;
}

.SearchBar_advancedRegionOpen__veaDp .SearchBar_advancedSearch__ybqdY {
  display: block;
}

/* Calendar and date styles - Add these based on your calendar component needs */

.SearchBar_dropdownDate__CL4yi {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 10px 20px 20px;
}

@media screen and (max-width: 922px) {

.SearchBar_dropdownDate__CL4yi {
    padding: 10px 0 20px
}
  }

.SearchBar_dropdownDateToggleHeader__euMcV {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}

@media screen and (max-width: 767px) {

.SearchBar_dropdownDateToggleHeader__euMcV {
    grid-template-columns: 0.25fr 2fr 0.25fr
}
  }

.SearchBar_toggleSwitch__y0r3Y {
  display: flex;
  border: 1px solid var(--color-grey-tint);
  border-radius: 3px;
  overflow: hidden;
}

.SearchBar_toggleButton__3JxIb {
  flex: 1;
  padding: 10px 15px;
  border: 0;
  background: transparent;
  font-size: 14px;
  color: var(--color-grey-medium);
  transition: all 0.2s ease;
}

.SearchBar_toggleButtonActive__8S6up {
  background-color: var(--color-red-10);
  color: #FFF;
}

.SearchBar_calendarHeader__Z81J4 {
  display: grid;
  grid-template-columns: 32px 1fr 32px;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.SearchBar_dateSelectors__euHBE {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.SearchBar_monthSelect__Q5AjM,
.SearchBar_yearSelect__fyNl6 {
  /* Existing styles */
  padding: 10px;
  border: 1px solid var(--color-grey-tint);
  border-radius: 3px;
  font-size: 14px;
  color: var(--color-marina);
  background-color: #FFF;
  
  /* Add these styles to fix the dropdown arrow */
  appearance: none; /* Removes default arrow */
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
  padding-right: 30px; /* Make room for custom arrow */
}

/* Optionally, if you want to change the arrow color to match your design */
.SearchBar_monthSelect__Q5AjM,
.SearchBar_yearSelect__fyNl6 {
  /* Alternative using a simpler arrow */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23495057' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
  background-size: 16px;
}

.SearchBar_monthSelect__Q5AjM {
  min-width: 140px;
}

.SearchBar_yearSelect__fyNl6 {
  min-width: 100px;
}

.SearchBar_yearChangeButtons__7MYvW {
  margin-bottom: -50px;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
}

.SearchBar_yearChange__9xNaM {
  background-color: transparent;
  width: 32px;
  height: 32px;
  color: var(--color-red-10);
  border: 0;
  border-radius: 3px;
  transition: background-color 0.2s ease;
}

.SearchBar_yearChange__9xNaM:hover:not(:disabled) {
  background-color: var(--color-grey-light);
}

.SearchBar_yearChange__9xNaM:disabled {
  background-color: var(--color-grey-light);
  color: var(--color-grey-tint);
}

.SearchBar_years__7P4fV {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

@media screen and (max-width: 922px) {

.SearchBar_years__7P4fV {
    grid-template-columns: 1fr;
    gap: 60px
}
  }

@media screen and (max-width: 768px) {

.SearchBar_years__7P4fV {
    gap: 30px
}
  }

.SearchBar_year__RONGk {
  position: relative;
}

.SearchBar_year__RONGk:first-child::after {
  position: absolute;
  top: 15px;
  bottom: 0;
  right: -15px;
  width: 1px;
  content: '';
  background-color: var(--color-grey-tint);
}

@media screen and (max-width: 922px) {

.SearchBar_year__RONGk:first-child::after {
    bottom: -30px;
    top: auto;
    left: 15px;
    right: 15px;
    width: auto;
    height: 1px
}
  }

@media screen and (max-width: 768px) {

.SearchBar_year__RONGk:first-child::after {
    bottom: -15px
}
  }

.SearchBar_year__RONGk h3 {
  margin: 0;
  margin-bottom: 8px;
  justify-content: center;
  height: 32px;
  margin-top: -12px;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  font-family: var(--font-family-content);
  font-weight: 600;
  color: var(--color-grey-medium);
}

.SearchBar_months__6gxwl {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 5px;
}

.SearchBar_month__Rdvun {
  border: 0;
  background: transparent;
  text-align: center;
  padding: 15px;
  border-radius: 3px;
  line-height: 1em;
  font-size: 15px;
  transition: all 0.2s ease;
}

.SearchBar_month__Rdvun:hover:not(:disabled) {
  background-color: var(--color-grey-light);
}

@media screen and (max-width: 922px) {

.SearchBar_month__Rdvun:hover:not(:disabled) {
    background-color: var(--color-red-10)
}
  }

.SearchBar_month__Rdvun:disabled {
  background-color: var(--color-grey-light);
  color: var(--color-grey-tint);
  cursor: not-allowed;
}

.SearchBar_monthSelected__jbbij {
  color: #FFF;
  background-color: var(--color-red-10);
  font-weight: 700;
}

.SearchBar_monthSelected__jbbij:hover {
  color: #FFF;
  background-color: var(--color-red-10);
  cursor: default;
}

.SearchBar_advancedSearchInputGroups__YzKsX {
  margin-top: 20px;
}

.SearchBar_advancedSearchInputGroup__QLtEI {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.SearchBar_duration__G__4I,
.SearchBar_flexibility___Eq1t,
.SearchBar_advancedSearchStartingPort__ANkR8,
.SearchBar_advancedSearchVisitingPort__On0Mw {
  width: 50%;
}

.SearchBar_duration__G__4I select,
.SearchBar_flexibility___Eq1t select,
.SearchBar_advancedSearchStartingPort__ANkR8 select,
.SearchBar_advancedSearchVisitingPort__On0Mw select,
.SearchBar_advancedSearchShips__MvDTK select {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--color-grey-tint);
  border-radius: 3px;
  font-size: 14px;
  color: var(--color-marina);
  background-color: #FFF;
}

.SearchBar_dropdownLinesShipsShips___4Lco {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 20px;
}


.SearchBar_calendarHeader__Z81J4 h3 {
  margin: 0;
  justify-content: center;
  text-align: center;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  font-family: var(--font-family-content);
  font-weight: 600;
  color: var(--color-grey-medium);
}

/* We can reuse your existing yearChange class for the navigation buttons */

.SearchBar_weekDays__VKKD5 {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  margin-bottom: 10px;
}

.SearchBar_weekDay__URP7q {
  text-align: center;
  font-size: 12px;
  font-family: var(--font-family-header);
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: var(--color-grey-medium);
  padding: 5px;
}

.SearchBar_calendarDays___RAX0 {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.SearchBar_calendarDay__uUTd0 {
  position: relative;
  border: 0;
  border-radius: 3px;
  background: transparent;
  text-align: center;
  padding: 15px;
  line-height: 1em;
  font-size: 15px;
  transition: all 0.2s ease;
}

@media screen and (max-width: 922px) {

.SearchBar_calendarDay__uUTd0 {
    padding: 12px
}
  }

.SearchBar_flexibleDay___o7ns {
  outline: 1px solid rgba(244, 8, 31, 0.33);
  background: rgba(244, 8, 31, 0.05);
}

.SearchBar_flexibilityIndicator__mpNt8 {
  position: absolute;
  bottom: 2px;
  right: 2px;
  font-size: 12px;
  color: var(--color-red-10);
  line-height: 1;
  font-family: var(--font-family-monospace);
}

/* Reusing month hover styles */
.SearchBar_calendarDay__uUTd0:hover:not(:disabled):not(.SearchBar_emptyDay__EOuLZ) {
  background-color: var(--color-grey-light);
}

/* Reusing month disabled styles */
.SearchBar_calendarDay__uUTd0:disabled,
.SearchBar_emptyDay__EOuLZ {
  background-color: var(--color-grey-light);
  color: var(--color-grey-tint);
  cursor: not-allowed;
}

/* Reusing monthSelected styles */
.SearchBar_selectedDay__j19v_ {
  color: #FFF;
  background-color: var(--color-red-10);
  font-weight: 700;
}

.SearchBar_selectedDay__j19v_:hover {
  color: #FFF;
  background-color: var(--color-red-10) !important;
  cursor: default;
}



.SearchBar_mobileClose__zGAHh {
  display: none;
}

.SearchBar_advancedRegionMobileClosed__L8Mmj {
  z-index: 10;
}

@media (max-width: 992px) {
    .SearchBar_advancedRegionMobileClosed__L8Mmj .SearchBar_searchBar__s0o08 {
      display: block;
      border-radius: 5px;
      overflow: hidden;
    }
      

      .SearchBar_advancedRegionMobileClosed__L8Mmj .SearchBar_searchBar__s0o08 .SearchBar_options__YI4TA {
        display: none;
      }
  }

@media (max-width: 992px) {

.SearchBar_advancedRegionMobileOpen__Hbthq {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    background-color: white
}

    
  
    .SearchBar_advancedRegionMobileOpen__Hbthq .SearchBar_mobileClose__zGAHh {
      display: block;
      position: absolute;
      top: 15px;
      right: 15px;
      padding: 15px;
      z-index: 10000;
      font-size: 24px;
      line-height: 1em;
    }

    .SearchBar_advancedRegionMobileOpen__Hbthq .SearchBar_innerWrapper____Scv {
      padding: 30px;
      width: 480px;
      max-width: 100%;
    }

    .SearchBar_advancedRegionMobileOpen__Hbthq .SearchBar_extras__HTdvF {
      display: none;
    }

    .SearchBar_advancedRegionMobileOpen__Hbthq .SearchBar_searchBar__s0o08 {
      display: block;
      height: auto;
    }

      .SearchBar_advancedRegionMobileOpen__Hbthq .SearchBar_searchBar__s0o08.SearchBar_searchBarOpen__NWk7U {
        z-index: 101;
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
      }

        .SearchBar_advancedRegionMobileOpen__Hbthq .SearchBar_searchBar__s0o08.SearchBar_searchBarOpen__NWk7U+.SearchBar_mobileClose__zGAHh {
          display: none;
        }

        .SearchBar_advancedRegionMobileOpen__Hbthq .SearchBar_searchBar__s0o08.SearchBar_searchBarOpen__NWk7U .SearchBar_option__Lsc7J {
          display: none;
        }

        .SearchBar_advancedRegionMobileOpen__Hbthq .SearchBar_searchBar__s0o08.SearchBar_searchBarOpen__NWk7U .SearchBar_optionOpen__JIy7O {
          display: block;
        }

        .SearchBar_advancedRegionMobileOpen__Hbthq .SearchBar_searchBar__s0o08.SearchBar_searchBarOpen__NWk7U .SearchBar_options__YI4TA {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
        
        .SearchBar_advancedRegionMobileOpen__Hbthq .SearchBar_searchBar__s0o08.SearchBar_searchBarOpen__NWk7U .SearchBar_dropdown__eBzaw {
          position: initial;
          top: auto;
          width: 100%;
          flex-grow: 1;
          overflow-y: auto;
        }

        .SearchBar_advancedRegionMobileOpen__Hbthq .SearchBar_searchBar__s0o08.SearchBar_searchBarOpen__NWk7U .SearchBar_searchButton__ufn2L {
          display: none;
        }

        .SearchBar_advancedRegionMobileOpen__Hbthq .SearchBar_searchBar__s0o08.SearchBar_searchBarOpen__NWk7U .SearchBar_dropdownDropdownListOptions__0wmiR {
          display: flex;
          flex-direction: column;
          max-height: calc(100vh - 270px);
          overflow-y: auto;
        }

      .SearchBar_advancedRegionMobileOpen__Hbthq .SearchBar_searchBar__s0o08 .SearchBar_options__YI4TA {
        grid-template-columns: unset;
      }

      .SearchBar_advancedRegionMobileOpen__Hbthq .SearchBar_searchBar__s0o08 .SearchBar_option__Lsc7J::after {
        bottom: 0;
        left: 15px;
        top: unset;
        right: 15px;
        height: 1px;
        width: auto;
      }

      .SearchBar_advancedRegionMobileOpen__Hbthq .SearchBar_searchBar__s0o08 .SearchBar_searchButton__ufn2L {
        border-radius: 5px;
        height: 64px;
      }
  }
