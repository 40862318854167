.priceGridContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.alternativeDropdowns {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
}

.dropdownContent {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  font-size: 15px;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-medium);
  border-radius: 3px;

  .dropdownItem {
    display: block;
    padding: 10px;
    line-height: 1em;
    width: 100%;
    text-align: left;
    color: var(--color-maroon);

    &:hover {
      background-color: var(--color-grey-light);
    }
  }

  .dropdownItemSelected {
    background-color: var(--color-red);
    color: var(--color-white);

    &:hover {
      background-color: var(--color-red-10);
    }
  }
}

.dropdownContentLeft {
  left: 0;
  max-width: 230px;
}

.dropdownContentRight {
  right: 0;
}

.dropdown {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  flex-direction: column;
  gap: 5px;
  max-width: 250px;
  min-width: 0;

  & svg {
    font-size: 16px;
  }

  &>label {
    display: flex;
    gap: 5px;
    line-height: 1.6em;
    font-size: 12px;
    font-family: var(--header-font);
    font-weight: 500;
    color: var(--color-grey-medium);
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
}

.dropdownOpen {
  & button > svg {
    transform: rotate(180deg) translateY(-2px);
  }
}

.dropdownDateOnly {
  min-width: 100%;
}

.dropdownButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 5px 8px;
  border: 1px solid var(--color-grey-shade);
  border-radius: 3px;
  font-size: 15px;
  letter-spacing: 0.05em;
  width: 100%;

  & > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-family: var(--header-content);
    color: var(--color-grey-deep);
    min-width: 0;
    flex: 1;

    & > svg {
      margin-top: -2px;
      color: var(--color-grey-dark);
    }

    & > :last-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 0;
    }
  }

  & > svg {
    margin-top: -2px;
  }
}

.priceGrid {
  display: flex;
  flex-direction: row;
  border: 1px solid var(--color-grey-tint);
  border-radius: 3px;
}

.priceGridLabel {
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: 12px;
  font-family: var(--header-font);
  font-weight: 500;
  color: var(--color-grey-medium);
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-bottom: -5px;
  line-height: 1.6em;

  & > svg {
    font-size: 16px;
  }
}

.priceGridItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  padding: 10px 5px;
  padding-bottom: 15px;
  flex-grow: 1;
  flex-basis: 0;

  border-right: 1px solid var(--color-grey-light);

  &:last-child {
    border-right: none;
  }

  label {
    font-size: 14px;
    font-family: var(--header-font);
    font-weight: 500;
    text-transform: uppercase;
    color: var(--color-maroon);
    letter-spacing: 0.05em;
  }

  &>svg {
    font-size: 1.5em;
  }
}


.priceGridItemPrice {
  font-size: 24px;
  font-family: var(--header-font);
  font-weight: 600;
  text-transform: uppercase;
  color: var(--color-red);
  letter-spacing: 0.05em;
}

.priceGridItemSelected {
  label {
    color: var(--color-red);
  }
}

.starbuy {
  .priceGridItemSelected {
    label {
      color: var(--color-star-buy-solid);
    }
  }

  .priceGridItemPrice {
    color: var(--color-star-buy-solid);

    svg {
      color: var(--color-star-buy-solid);
    }
  }
}

.upgradePrices {
  font-size: 12px;
  color: var(--color-grey-dark);
  text-align: center;
}

.alternativeCabinTable {
  width: 100%;
  border-collapse: collapse;

  th {
    font-size: 12px;
    font-family: var(--header-font);
    font-weight: 500;
    text-transform: uppercase;
    color: var(--color-grey-medium);
    letter-spacing: 0.05em;
    
  }

  td, th {
    padding: 15px 5px;

    &:first-child {
      padding-left: 10px;
    }

    &:last-child {
      padding-right: 10px;
    }
  }

  td {
    text-align: center;
  }
}

.alternativeCabinTableRow {
  cursor: pointer;
  &:hover {
    background-color: var(--color-grey-light);
  }
}

.alternativeCabinTableRowSelected {
  background-color: var(--color-red);
  color: var(--color-white);

  &:hover {
    background-color: var(--color-red-10);
  }
}

.dropdownItemContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dropdownItemPrice {
  font-size: 0.9em;
  color: var(--color-text-secondary);
  margin-left: 1rem;
}


