.pricePerNight {
  font-size: 16px;
  font-family: var(--font-content);
  font-weight: 500;
  color: var(--color-maroon);
  margin-top: -20px;
  margin-bottom: -10px;
  text-align: right;
}

.starbuy {
  color: var(--color-star-buy-solid);
  font-weight: 600;
  font-family: var(--header-font);
}
